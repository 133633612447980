import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';

class AllProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Container className='pt-5'>
                    <Row className='text-center'>
                        <Col lg={4} md={6} sm={12} className='p-2'>
                        <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Web Developer at Cirk-L </Card.Title>
                                <Card.Text className='projectCardDes'>
                                   <p>
                                    Recently I worked as a Full Stack Web Developer in Cirk-L. They work on new concept of Working Wear and running a multinational E-commerce platform. In this project I worked in backend and frontend development. The payment gateway, performance optimize, multi-currency, multi vat/tax/shipment calculation and multi-language were my key responsibilities.
                                    </p>
                                    <hr></hr>
                                    <p>
                                    JavaScript, PHP, HTML, CSS, Woo commerce, WordPress, Klarna payment, multinational shipment, tax calculation and language, Performance Optimize, server Configuration, Plugin development
                                    </p>
                                </Card.Text>
                            </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} sm={12} className='p-2'>
                        <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Learning project in React js </Card.Title>
                                <Card.Text className='projectCardDes'>
                                   <p>
                                   My proposed learning project is to create a simple Profile application using ReactJS and laravel as backend. http://react.toihid.com/
                                    </p>
                                    <hr></hr>
                                    <p>
                                    I used Laravel for managing backend and creating API. For implementing frontend I used React, HTML, CSS, Javascript. It runs in Ngnix server and source codes in git.
                                    </p>
                                </Card.Text>
                            </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} sm={12} className='p-2'>
                        <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Aoyama APIs</Card.Title>
                                <Card.Text className='projectCardDes'>
                                    <p>
                                        Project  Aoyama was for Aoyama Tailor is one of the largest suit retailer in Japan. Established in 1964, now it's a TSE-liste company sales of over 240 billion yen. I developed Custom CMS for admin Create Restful API , Banner Export, and  Responsive design. 
                                    </p>
                                    <hr></hr>
                                    <p>
                                       Cakephp, JavaScript , PHP, Bootstrap, HTML, CSS, Frontend and backend, Jenkins, API
                                    </p>
                                </Card.Text>

                            </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='p-2'>
                        <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Intern management system</Card.Title>
                                <Card.Text className='projectCardDes'>
                                    <p>
                                    Bloom GBG is an uncommon and unpolitical project where companies in our region offer valuable internships to our valuable youth in grades 8 and 9. I was involved in front-end and back-end development.
                                    </p>
                                    <hr></hr>
                                    <p>
                                    JavaScript, Laravel, Symphony, Twig, Mysql, HTML, CSS, WordPress, API integration, Admin dashboard, Requirement analysis and planning server Configuration. 
                                    </p>
                                </Card.Text>

                            </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='p-2'>
                        <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Web portal</Card.Title>
                                <Card.Text className='projectCardDes'>
                                    <p>
                                    Bangladesh government’s National Portal unites 46,500 government offices virtually, features detailed information on nearly 400 services from 36 ministries and hosts nearly 1653 government forms. I did UI design, responsive design, implement SEO and bug fixing.
                                    </p>
                                    <hr></hr>
                                    <p>
                                    JavaScript, Python, Drupal, Phalcon, Cakephp, Bootstrap, HTML, CSS, Responsive design, Troubleshooting, communication with client and requirement analysis
                                    </p>
                                </Card.Text>

                            </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='p-2'>
                            <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>E learning platform</Card.Title>
                                <Card.Text className='projectCardDes'>
                                    <p>
                                    E-learning with the help of this Bangla course material, more than 800000 teachers of Bangladesh will be able to receive this training at their convenience at no cost. Participants of this course will get a certificate upon successful completion of course through attending online quiz, assignment, and exams. I developed some features, trouble shooting and front end development.
                                    </p>
                                    <hr></hr>
                                    <p>
                                    JavaScript, Cakephp, Bootstrap, HTML, CSS, Responsive design, Troubleshooting, communication with client and requirement analysis
                                    </p>
                                </Card.Text>

                            </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} sm={12} className='p-2'>
                            <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Supply Chain Management</Card.Title>
                                <Card.Text className='projectCardDes'>
                                    <p>
                                    In there I was involved in project for SCM (Supply Chain Management) of Prime Bank of Bangladesh. I developed some modules such as Requisition, Approval process, Insurance Management, Vehicle Management. 
                                    </p>
                                    <hr></hr>
                                    <p>
                                    JavaScript, PHP, Bootstrap, HTML, CSS, Frontend and Back end, Troubleshooting, communication with client and requirement analysis
                                    </p>
                                </Card.Text>

                            </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} sm={12} className='p-2'>
                            <Card>
                            <Card.Body className='projectCard'>
                                <Card.Title className='projectCardTitle'>Cadet Assessment System</Card.Title>
                                <Card.Text className='projectCardDes'>
                                    <p>
                                    I also worked another project called Cadet Assessment for Bangladesh Military Academy (BMA). I involved development team for the modules of Cadet info, Teacher assign, Exam Marking, Assignment, Comments, Result calculation and result publish.
                                    </p>
                                    <hr></hr>
                                    <p>
                                    JavaScript, PHP, Bootstrap, HTML, CSS, Frontend and Back end, Troubleshooting, communication with client and requirement analysis
                                    </p>
                                </Card.Text>

                            </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </Fragment>
        );
    }
}

AllProjects.propTypes = {};

export default AllProjects;
